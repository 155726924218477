'use client';

import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { Age18Outlined, BanFilled } from '@packages/icons-react';

const Footer: React.FC = () => {
  const { t } = useTranslation(['home', 'common']);

  const data = [
    {
      title: t('common:menu.sportsbook'),
      list: ['M8 Bet', 'AFB 88', 'Obet Sport'],
    },
    {
      title: t('common:menu.casino'),
      list: [
        'Sexy Game',
        'CT855 Casino',
        'WM Casino',
        'Pragmatic Play',
        'AFB Casino',
        'SA Casino',
        'RGC Games',
      ],
    },
    {
      title: t('common:menu.gaming'),
      list: [
        'Pocket Games',
        'Joker Gaming',
        'Pragmatic Play',
        'AFB Gaming',
        'Mancala Gaming',
        'King Maker',
        'Smart Soft Gaming',
        'Gamemediaworks',
        'Popok Gaming',
        'Netent',
        'Pegasus',
        'Fox Taixiu Game',
        'Slot OX',
        'Habanero',
      ],
    },
  ];

  return (
    <footer>
      <div className="wrapped-content my-6 sm:my-9">
        <div className="bg-oxford my-6 h-[1px] w-full sm:my-9" />
        <div className="flex gap-14 xl:justify-between">
          <div className="mx-auto w-full text-center sm:max-w-md xl:mx-0 xl:max-w-xs xl:text-left">
            <p>{t('home:footer.title')}</p>
            <p className="text-gray text-xs">{t('home:footer.description')}</p>
          </div>

          {data.map((item, i) => {
            return (
              <div className="hidden flex-col gap-6 xl:flex" key={i}>
                <h3 className="text-primary">{item.title}</h3>
                <div className="flex flex-col gap-2">
                  {item.list.map((listItem, j) => {
                    return (
                      <p className="text-sm" key={j}>
                        {listItem}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}

          <div className="text-gray hidden gap-4 text-[40px] xl:flex">
            <Age18Outlined />
            <BanFilled />
          </div>
        </div>
      </div>

      <div className="bg-dark wrapped-content w-full py-4 md:py-6">
        <div className="flex flex-col items-center gap-3 md:gap-6">
          <p className="text-sm">©2023 L68 Bet. All rights reserved.</p>
          <div className="flex flex-wrap gap-2">
            {[
              'youtube',
              'discord',
              'instagram',
              'facebook',
              'telegram',
              'twitter',
            ].map((item) => {
              return (
                <Image
                  alt={item}
                  height={24}
                  key={item}
                  src={`/images/icon/${item}.png`}
                  width={24}
                />
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
